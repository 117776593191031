import { Inject, Injectable, Pipe, PipeTransform } from '@angular/core';
import { ITranslationService, I18NEXT_SERVICE, I18NEXT_NAMESPACE, I18NEXT_SCOPE } from 'angular-i18next';

@Injectable()
@Pipe({
  name: 'exist',
  pure: false
})
export class existPipe implements PipeTransform {

  constructor(
      @Inject(I18NEXT_SERVICE) private translateI18Next: ITranslationService,
      @Inject(I18NEXT_NAMESPACE) private ns: string | string[],
      @Inject(I18NEXT_SCOPE) private scope: string | string[]) {}

  public transform(key: string, options?: any): boolean {
    options = this.prepareOptions(options);
    return this.translateI18Next.exists(key, options);
  }

  private prepareOptions(options: any) {
    options = options || {};
    if (options.context != null)
      options.context = options.context.toString();
    return options;
  }
}