import { Injectable, OnDestroy, Inject } from '@angular/core';
import { ContextService } from './context.service';
import { Subscription, Observable, from, of, config, BehaviorSubject } from 'rxjs';
import { ITranslationService, I18NEXT_SERVICE, I18NextLoadResult, I18NextModule } from 'angular-i18next';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import * as moment from 'moment';
import { ConfigService } from '../configuration/config.service';
import { LocalizationUrlService } from './data/localization-url.service';
import { StringHelper } from '../helpers/string-helper';
import { LocalizationRequester } from '../models/localization-requester';

//https://angular.io/guide/i18n#setting-up-the-locale-of-your-app
registerLocaleData(localeFr);

@Injectable({
  providedIn: 'root'
})
export class CultureManagerService implements OnDestroy {
  private subscriptions = new Subscription();

  //#region resourcesReloaded
  private resourcesReloadedSubject?: BehaviorSubject<string>;
  public resourcesReloaded$: Observable<string>;
  private resourcesReloaded: string;
  //#endregion

  public tenantCode: string;

  constructor(
    private configService: ConfigService,
    private context: ContextService,
    private localizationUrlService: LocalizationUrlService,
    @Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService) {
    console.log("Init CultureManager");
    this.tenantCode = "";

    this.resourcesReloaded = "";
    this.resourcesReloadedSubject = new BehaviorSubject<string>(this.resourcesReloaded);
    this.resourcesReloaded$ = this.resourcesReloadedSubject.asObservable();

    this.subscriptions.add(this.context.Culture$.subscribe((value) => {
      if (!!value) {
        if (this.i18NextService.language === value) {
          return of();
        }
        else {
          return this.Use(value);
        }
      }
      else {
        return this.Use(this.configService.Config.defaultCulture);
      }
    }));
    this.subscriptions.add(this.i18NextService.events.languageChanged.subscribe((e: any) => {
      console.log(`language changed ${JSON.stringify(e)}`);
      this.resourcesReloadedSubject.next(this.tenantCode);
    }));
    this.subscriptions.add(this.i18NextService.events.loaded.subscribe((e: any) => {
      if (e) {
        var loaded = false;
        var namespace = StringHelper.isNullOrWhitespace(this.tenantCode) ? "WebUserProfile" : this.tenantCode;
        let namespaceByLanguage: any[][] = e;
        let culture = this.context.Culture;
        if (namespaceByLanguage != undefined) {
          let namespaceByLanguageByCulture = namespaceByLanguage[culture];
          if (namespaceByLanguageByCulture == undefined) {
            let key = Object.keys(namespaceByLanguage).find(c => c.split('-')[0] == culture);
            namespaceByLanguageByCulture = namespaceByLanguage[key];
          }
          if (namespaceByLanguageByCulture != undefined) {
            this.i18NextService.setDefaultNamespace(namespace);
            this.resourcesReloaded = this.tenantCode;
            this.resourcesReloadedSubject.next(this.resourcesReloaded);
          }
        }
      }
    }));

  }

  public changeNamespace(tenantCode: string) {
    console.log(`reset translations tenantcode change from '${this.tenantCode}' to '${tenantCode}' [${this.context.Culture}]`);
    this.tenantCode = tenantCode;
    this.i18NextService.options.backend.loadPath = this.localizationUrlService.getResourcesUrl(LocalizationRequester.WebAlertAnswer, this.configService, this.tenantCode);
    this.Use(this.context.Culture)
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public Use(culture: string): Observable<any> {
    var namespace = StringHelper.isNullOrWhitespace(this.tenantCode) ? "WebUserProfile" : this.tenantCode;
    this.i18NextService.reloadResources(culture, [namespace], () => {
      console.log(`Translations for ${culture} => ${namespace}: reloaded`);
    });


    moment.locale(culture);
    return from(this.i18NextService.changeLanguage(culture));
  }
}

export function localeIdFactory(i18next: ITranslationService) {
  return i18next.language;
}


