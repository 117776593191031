<i2a-nav-bar-base [displayMenu]="displayMenu">
  <ng-container menuLogo>
    <div class="logoContainer" [attr.style]="logoContainerStyle">
      <img [src]="logo" [attr.style]="logoStyle" />
    </div>
  </ng-container>

  <ng-container menuBrand>
    <div>
      <div class="title" #navbarTitle  >
        {{"navigationBar.title" | translate }}<br>
      </div>
      <div class="sub-title" #navbarSubTitle >
        {{"navigationBar.subTitle" | translate }}
      </div>
    </div>
  </ng-container>

  <ng-container menuUser>
    <ng-container *ngIf="model">
      <div class="user">
        <i2a-user-photo class="pr-1" [size]="36">
        </i2a-user-photo> <span class="connected-account">{{model.userFullName}}</span>
      </div>
    </ng-container>
  </ng-container>

</i2a-nav-bar-base>