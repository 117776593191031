import { OnInit, OnDestroy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertAnswerModel } from 'src/app/i2a/core/models/alert-answer-model';
import { Subscription } from 'rxjs';
import { AnswerBusinessService } from 'src/app/i2a/core/services/business/answer-business.service';

@Component({
    selector: 'i2a-alert-form-base',
    template: ''  
})
export abstract class AlertFormBase implements OnInit, OnDestroy {
    public model: AlertAnswerModel;
    private subscriptions = new Subscription();

    constructor(
        protected answerBusiness: AnswerBusinessService,
        protected route: ActivatedRoute,
        protected router: Router) {
    }

    ngOnInit() {
        this.subscriptions.add(this.answerBusiness.Alert$.subscribe(alert => {
            this.model = alert;
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

}
