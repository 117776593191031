import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from 'rxjs';
import { first, map, filter } from 'rxjs/operators';
import { ContextService, CultureManagerService } from '../i2a/core/i2a-core.module';
import { StringHelper } from '../i2a/core/helpers/string-helper';

@Injectable()
export class TenantCodeResolver implements Resolve<Observable<any>> {
  constructor(
    private router: Router,
    private context: ContextService,
    private cultureManager: CultureManagerService) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Observable<any> | Promise<any> | any> {
    console.log(`set tenantcode ${route.params["tenantCode"]}`);
    let tenantCode: string = route.params["tenantCode"];
    
    this.cultureManager.changeNamespace(tenantCode);
    return this.cultureManager.resourcesReloaded$.pipe(
      filter((value) => {
        return (StringHelper.isNullOrWhitespace(value) && StringHelper.isNullOrWhitespace(tenantCode)) ||
          value == tenantCode;
      }),
      map(() => {
        console.log(`2- resources loaded, set tenantCode: ${tenantCode}`)
      }),
      first()).toPromise().then(() => {
        return this.cultureManager.Use(this.context.Culture).pipe(
          map(() => {
            console.log(`2- culture changed, set : ${this.context.Culture}`)
            this.context.TenantCode = tenantCode;
            return tenantCode;
          }),
          first()
        ).toPromise();
      });
  }
}