import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';

export interface Ii2aConfig {
  apiEndPoint?: string;
  urlPrefix?: string;
  defaultCulture?: string;
  clientId?: string;
}

@Injectable()
export class ConfigService {

  private configSubject?: BehaviorSubject<Ii2aConfig>;
  public Config$: Observable<Ii2aConfig>

  get Config(): Ii2aConfig {
    return this.configSubject.value;
  }

  set Config(config: Ii2aConfig) {
    this.configSubject.next(config);
  }


  constructor() {
    this.configSubject = new BehaviorSubject<Ii2aConfig>(null);
    this.Config$ = this.configSubject.asObservable();
  }

  public load(http: HttpClient, toastr: ToastrService): Promise<any> {
    console.log("load config");
    return http.get<Ii2aConfig>("assets/config/config.json").toPromise().then((data) => {
      this.configSubject.next(data);
      console.log(`==> loaded ${JSON.stringify(data)}`);
    }).catch((error) => {
      toastr.error(error.error.ExceptionMessage, "Error");
    });
  }

  public loadConfigFile(): Promise<boolean> {
    console.log("load config");
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', 'assets/config/config.json');

      xhr.addEventListener('readystatechange', () => {
        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
          console.debug(`==> loaded ${xhr.responseText}`);
          let data: Ii2aConfig = JSON.parse(xhr.responseText);
          this.configSubject.next(data);
          resolve(true);
        } else if (xhr.readyState === XMLHttpRequest.DONE) {
          reject(false);
        }
      });

      xhr.send(null);
    });
  }
}

