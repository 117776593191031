import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfigService, Ii2aConfig } from 'src/app/i2a/core/configuration/config.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-probes-status',
  templateUrl: './probes-status.component.html',
  styleUrls: ['./probes-status.component.scss']
})
export class ProbesStatusComponent implements OnInit, OnDestroy {
  public settings: any;
  public subscriptions = new Subscription();

  constructor(private config: ConfigService) {
    config.Config$.subscribe(data => this.settings = {
      "status": "OK",
      "environment": environment,
      "apiEndPoint": data.apiEndPoint,
      "urlPrefix": data.urlPrefix,
      "defaultCulture": data.defaultCulture,
      "clientId": data.clientId,
      "production" : environment.production
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
