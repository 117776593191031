import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import * as moment from 'moment';
import i18n from 'i18next';

@Injectable({
  providedIn: 'root'
})

export class NgbDatePickerHelperService extends NgbDateParserFormatter {
  public placeholder = '';

  constructor() {
    super();
    this.placeholder = NgbDatePickerHelperService.getDatePattern(i18n.language);
  }

  public static getDatePattern(locale) {
    var formatter = new Intl.DateTimeFormat(locale).formatToParts();

    return formatter.map(function (e) {
      switch (e.type) {
        case 'month':
          return 'MM';
        case 'day':
          return 'DD';
        case 'year':
          return 'YYYY';
        default:
          return e.value;
      };
    }).join('');
  }

  parse(value: string): NgbDateStruct {
    if (value) {
      value = value.trim();
      let mdt = moment(value, this.placeholder)
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    if (!date) return '';
    let mdt = moment([date.year, date.month - 1, date.day]);
    if (!mdt.isValid()) return '';
    return mdt.format(this.placeholder);
  }
}