<ng-container *ngIf="model">
  
  <app-page-container [title]="'alert.type.' + model.type | translate">
  
    <div content-title class="id-alert-header">
      <div class="id-photo">
        <img src="{{ProviderImage}}" [style.width.px]="40" [style.height.px]="40" [style.margin.px]="10" />
      </div>
      <div class="col-12  text-center id-title">
        <span class="id-alert-title">
          {{'alert.type.' + model.type | translate}}
        </span>
      </div>
      <div class="col-12 text-center id-sub-title">
        <span class="id-alert-subtitle">
          {{model.resourceName}}
        </span>
      </div>
    </div>
  
    <div *ngIf="model.end" class="container-fluid id-alert-closed" content-closed>
      <div class="row align-items-center h-100">
  
        <div class="col-12 label" *ngIf="model.endUserName == null">
          {{ "alert.dismissed" | translate : { endDate: (model.end | amDateFormat: 'LLLL') } }}
        </div>
  
        <div class="col-12 label" *ngIf="model.endUserName != null">
          {{ "alert.dismissedBy" | translate : { endDate: (model.end | amDateFormat: 'LLLL'), endUserName : model.endUserName } }}
        </div>
  
      </div>
    </div>
  
    <div class="alert-content-subtitle" content-subtitle>
      <!-- <p>
        <img src="{{ProviderImage}}" [style.width.px]="23" [style.height.px]="23" />
        {{model.resourceName}}
      </p> -->
      <p>
        <app-criticality-to-icon [criticality]="model.criticality" class="criticality"></app-criticality-to-icon>
        {{model.actionDate | amDateFormat: 'LLLL' }}
      </p>
      <p>
        {{model.title}}<br />
      </p>
    </div>

    <ng-container content-body>
    
      <p class="id-description">{{model.description}}</p>
    
      <div class="row">

        <ng-container *ngIf="Constants.SUSPICIOUS_ACCESS == model.type">
          <div *ngIf="model.explanation" class="col-12">
            <div class="card id-explanation">
              <div class="card-body">
                <table>
                  <tbody>
                    <tr>
                      <td class="id-explanation-picto">
                        <div class="id-picto" style="padding: 9px 17px">
                          <fa-icon [icon]="['fal', 'info']"></fa-icon>
                        </div>
                      </td>
                      <td class="id-explanation-description">
                        <p>{{model.explanation}}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-12 pt-3">
          </div>
        </ng-container>
        
        <ng-container *ngIf="Constants.SUSPICIOUS_CONFIGURATION_CHANGE == model.type">
          <div class="col-12">
            <ul>
              <li *ngFor="let reason of model.reasons">
                {{reason}}
              </li>
            </ul>
          </div>
        </ng-container>

        <div class="container-fluid">
          <app-alert-details></app-alert-details>
        </div>

      </div>
      
      <app-alert-processed></app-alert-processed>
      
      <router-outlet></router-outlet>
    
    </ng-container>
  
  </app-page-container>

</ng-container>