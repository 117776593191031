import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DatasourceService } from '../../services/datasource.service';
import { LocalizationRequester } from '../../models/localization-requester';

@Injectable({
  providedIn: 'root'
})
export class LocalizationDatasourceService {

  private route: string = "localizations";

  constructor(private datasource: DatasourceService) {
    console.log("Init LocalizationDatasourceService");

  }

  public GetLanguages(): Observable<string[]> {
    let partialUrl = `${this.route}/languages`;

    return this.datasource.Get<string[]>(partialUrl, null);
  }

}
