<div class="container-fluid">
  <div class="row">
    <div class="col pl-0 pr-0">
      <div [ngClass]="{'id-header': useBackgroundImage}" [attr.style]="test">

        <!-- navbar -->
        <ng-content select="[navbar]" ></ng-content>

      </div>
    </div>
  </div>