import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Md5 } from 'ts-md5/dist/md5';
import { DOCUMENT } from '@angular/common';
import { ContextService } from './i2a/core/i2a-core.module';
import { Subscription } from 'rxjs';
import { StringHelper } from './i2a/core/helpers/string-helper';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'web-alert-answer';
  loading = false;

  private subscriptions = new Subscription();

  
  loadStyle(tenantCode:string) {
    var styleName :any = "i2a";
      
      if (!StringHelper.isNullOrWhitespace(tenantCode))
      {
        styleName = Md5.hashStr(tenantCode);
      } 

    var css = `${styleName}.css`;
    const head = this.document.getElementsByTagName('head')[0];
    let themeLink = this.document.getElementById('client-theme') as HTMLLinkElement;
    console.log("Loading " + styleName);
    if (themeLink) {
      themeLink.href = css;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = css;

      head.appendChild(style);
    }
  }
  
  constructor(
    @Inject(DOCUMENT) private document: Document,
    public context: ContextService,
    private router: Router) {
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });
    this.subscriptions.add(context.TenantCode$.subscribe(tenantCode => {
      this.loadStyle(tenantCode);
    }));
  }

  
  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}