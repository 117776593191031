import { NgModule, ModuleWithProviders, Optional, SkipSelf, APP_INITIALIZER, LOCALE_ID } from "@angular/core";

import { ContextService } from './services/context.service';
import { DatasourceService } from "./services/datasource.service";
import { localeIdFactory, CultureManagerService } from "./services/culture-manager.service";
import { LocalizationDatasourceService } from "./services/data/localization-datasource.service";


import { I18NextModule, I18NEXT_SERVICE } from 'angular-i18next';
import { translatePipe } from './libs/i18next/translatePipe';
import { existPipe } from './libs/i18next/existPipe';
import { TokenResolver } from './resolvers/token.resolver';
import { ToastrService } from 'ngx-toastr';
import { Constants } from './models/Constants';
import { ConfigService } from './configuration/config.service';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UrlBuilderHelperService } from 'src/app/helpers/url-builder-helper.service';
import { LocalizationUrlService } from './services/data/localization-url.service';
import { ToasterHttpErrorInterceptor } from './Interceptors/toaster-http-error.interceptor';
import { AuthenticationHttpInterceptor } from './Interceptors/authentication-http.interceptor';
import { appInit } from './appInit';

export * from "./services/context.service";
export * from "./services/datasource.service";
export * from "./services/culture-manager.service";
export * from "./services/data/alert-answer-datasource.service";



@NgModule({
  declarations: [
    translatePipe,
    existPipe
  ],
  imports: [
    I18NextModule.forRoot(),
  ],
  providers: [
  ],
  exports: [
    translatePipe,
    existPipe
  ]
})

export class I2aCoreModule {

  constructor(@Optional() @SkipSelf() parentModule: I2aCoreModule) {
    if (parentModule) {
      throw new Error(
        'I2aCoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(environment: any): ModuleWithProviders<I2aCoreModule> {
    return {
      ngModule: I2aCoreModule,
      providers: [
        ConfigService,
        ContextService,
        DatasourceService,
        LocalizationDatasourceService,
        UrlBuilderHelperService,
        LocalizationUrlService,
        translatePipe,
        existPipe,
        CultureManagerService,
        TokenResolver,
        Constants,
        { provide: I18NextModule },
        { provide: 'env', useValue: environment },
        { provide: HTTP_INTERCEPTORS, useClass: ToasterHttpErrorInterceptor, deps: [ToastrService, I18NEXT_SERVICE], multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthenticationHttpInterceptor, deps: [ContextService, ConfigService], multi: true },
        { provide: APP_INITIALIZER, useFactory: appInit, deps: [ConfigService, I18NEXT_SERVICE, LocalizationUrlService], multi: true },
        { provide: LOCALE_ID, deps: [I18NEXT_SERVICE], useFactory: localeIdFactory },
        { provide: HTTP_INTERCEPTORS, useClass: ToasterHttpErrorInterceptor, deps: [ToastrService, I18NEXT_SERVICE], multi: true }

      ]
    }
  }

}
