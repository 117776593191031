<div class="id-navbar">
  <nav class="navbar navbar-expand-md">
    <!-- <ng-template #logo>
      <ng-content select="[menuLogo]"></ng-content>
    </ng-template> -->
    <div [ngClass]="{'navbar-logo-left': !isIE, 'float-left': true}">
        <ng-content select="[menuLogo]"></ng-content>
    </div>
    <div class='navbar-brand navbar-text p-0'>
      <ng-content select="[menuBrand]"></ng-content>
    </div>
    <div class='navbar-logo-center w-100'>
    </div>
    <ng-container *ngIf="displayMenu">
      <div>
        <div class="navbar-nav float-right">
          <ng-content select="[menuUser]"></ng-content>
        </div>
      </div>
    </ng-container>
  </nav>
</div>