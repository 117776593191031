import { Component, OnInit, Input, ViewChild, Inject, ChangeDetectorRef} from '@angular/core';
import { AlertAnswerModel } from 'src/app/i2a/core/models/alert-answer-model';
import { Subscription, Observable } from 'rxjs';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AnswerBusinessService } from 'src/app/i2a/core/services/business/answer-business.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DismissStatus } from 'src/app/models/dismiss-status';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { DatasourceService } from 'src/app/i2a/core/i2a-core.module';
import { Feedback } from 'src/app/i2a/core/models/feedback';
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { NgbDatePickerHelperService } from 'src/app/helpers/ngbDatePicker-helper';
import i18n from 'i18next';

export interface IFeedbackReason {
  value: string,
  viewValue: string
}

@Component({
  selector: 'app-dismiss-form',
  templateUrl: './dismiss-form.component.html',
  styleUrls: ['./dismiss-form.component.scss'],

  providers: [
    {provide: NgbDateParserFormatter, useClass: NgbDatePickerHelperService}
  ]
})
export class DismissFormComponent implements OnInit {
  // @ViewChild('commentInput')
  // commentInput: any;

  //#region Feedback 

  public feedbacks: IFeedbackReason[] = [
    { value: '1', viewValue: 'feedback.reasons.falsePositive' },
    { value: '2', viewValue: 'feedback.reasons.notConcernedByTheAlert' },
    { value: '3', viewValue: 'feedback.reasons.dontUnderstandTheAlert' },
    { value: '4', viewValue: 'feedback.reasons.trueSecurityIncident' }
  ];
 
  public isSendingUsFeeback: boolean = false;
  public canBeContacted: boolean = false;
  public submiting: boolean;

  public selectedFeedbackReasonsControl = new FormControl('');
  public feedbackCommentControl = new FormControl('');
  public canBeContactedControl = new FormControl(false);

  //#endregion

  private submitting: boolean = false;
  private isSuspicious: boolean;
  private isValid: boolean;
  private isUnknown: boolean;
  private maxValidityDate: NgbDate;
  private minValidityDate: NgbDate;
  private defaultFormatDate: string;

  public model: AlertAnswerModel;
  private subscriptions = new Subscription();
  private myForm: FormGroup;
  private comment = new FormControl('');
  private withValidUntilDate = new FormControl(false);
  private validUntilDate = new FormControl('');

  private dateToShow: string;

  private dismissStatus: DismissStatus;
  @Input()
  set status(status: DismissStatus) {
    this.dismissStatus = status;

    this.isSuspicious = this.dismissStatus == DismissStatus.IsSuspicious;
    this.isValid = this.dismissStatus == DismissStatus.IsValid;
    this.isUnknown = this.dismissStatus == DismissStatus.IsUnknown;
  }

  constructor(
    private datasource: DatasourceService,
    private answerBusiness: AnswerBusinessService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    @Inject(I18NEXT_SERVICE) private translateI18Next: ITranslationService) {
      this.isValid = false;
      this.isSuspicious = false;
      this.isUnknown = false;
      this.createForm();
  }

  private createForm() {
    this.myForm = this.formBuilder.group({
      comment: this.comment,
      validUntilDate: this.validUntilDate,
      withValidUntilDate: this.withValidUntilDate,

      feedbackCommentControl: this.feedbackCommentControl,
      canBeContactedControl: this.canBeContactedControl,
      selectedFeedbackReasonsControl: this.selectedFeedbackReasonsControl
    });
  }

  ngOnInit() {
    this.subscriptions.add(this.answerBusiness.Alert$.subscribe(alert => {
      this.model = alert;
      let today = moment();
      this.minValidityDate = new NgbDate(today.year(), today.month() + 1, today.date());
      let validityDate = moment(this.model.maxValidityDate);
      if (this.model.maxValidityDate != null) {
        this.maxValidityDate = new NgbDate(validityDate.year(), validityDate.month() + 1, validityDate.date());
        this.validUntilDate.setValue(this.maxValidityDate);
      }
      if (alert.canDismiss) {
        this.submitting = false;
      }
    }));
    // this.scrollToBottom();
    // this.focusToCmment();
    this.defaultFormatDate = NgbDatePickerHelperService.getDatePattern(i18n.language).toLowerCase();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngAfterContentChecked() {
    this.cdRef.detectChanges();
  }

  onSummary() {
    this.router.navigate(['../summary'], { relativeTo: this.route });
  }

  private submitForm() {
    this.submitting = true;
    let comment = this.myForm.get('comment').value as string;
    let action: Observable<any>;

    let feedback = this.getFeedbackForm();

    if (this.isValid) {
      let validUntilDate = null;
      if (this.withValidUntilDate.value) {
        if (this.myForm.get("validUntilDate").value != null && this.myForm.get("validUntilDate").value != "") {
          let validUntilDateInput: NgbDate = this.myForm.get("validUntilDate").value;
          validUntilDate = new Date(validUntilDateInput.year, validUntilDateInput.month - 1, validUntilDateInput.day); //? Why MONTH - 1 because NgbDate month tab index start at 1 
        }
      }
      action = this.answerBusiness.IsValid(comment, validUntilDate, feedback);
    }
    else if (this.isSuspicious) {
      action = this.answerBusiness.IsSuspicious(comment, feedback);
    }
    else if (this.isUnknown) {
      action = this.answerBusiness.IsUnknown(comment, feedback);
    }

    this.subscriptions.add(action.subscribe(
      result => {
        this.toastr.success(this.translateI18Next.t("alert.dialog.successMessage"));
        this.answerBusiness.LoadAlert();
        this.router.navigate(['..'], { relativeTo: this.route });
      },
      error => {
        this.submitting = false;
        console.error("Error during post");
      },
      () => {
        // 'onCompleted' callback.
        // No errors, route to new page here
      }
    ));
  }

  getFeedbackForm(): Feedback {
    if (this.selectedFeedbackReasonsControl.value !== '') {
      if (this.feedbackCommentControl.value !== '') {
        const feedback: Feedback = {
          reason: this.selectedFeedbackReasonsControl.value.viewValue,
          comment: this.feedbackCommentControl.value,
          canBeContacted: this.canBeContacted ?? false
        };
        return feedback;
      }
      this.isValid = false;      
    }
    return null;
  }

  isTemporaryAccessValid()
  {
    return this.withValidUntilDate.value && this.myForm.get("validUntilDate").value != "" || !this.withValidUntilDate.value;
  }

  getBackgroundColor(): string {
    return this.isSendingUsFeeback ? '#efefef' : 'inherit'
  }

  onChangeFeedbackReason(): void {
    this.isSendingUsFeeback = this.selectedFeedbackReasonsControl.value !== null;
  }

  onChangeFeedbackCanBeContacted(): void {
    this.canBeContactedControl.setValue(this.canBeContacted);
  }

  ngAfterViewChecked() {
  }
}