import { Constants } from '../i2a/core/models/Constants';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProviderHelper {

  constructor() {
  }

  public static GetProviderImage(providerId: string, providerTypeId: string): string {
    let constants = new Constants;

    if (providerId === constants.PROVIDER_ID_MICROSOFT) {
      if (providerTypeId === constants.PROVIDER_TYPE_MAILING) {
        return "assets/images/provider/MSexchange.svg";
      } else if (providerTypeId === constants.PROVIDER_TYPE_PERSONAL_STORAGE) {
        return "assets/images/provider/onedrive.svg";
      } else if (providerTypeId === constants.PROVIDER_TYPE_INTRANET) {
        return "assets/images/provider/sharepoint.svg";
      } else if (providerTypeId === constants.PROVIDER_TYPE_GENERIC) {
        return "assets/images/provider/adfs.svg";
      } else if (providerTypeId === constants.PROVIDER_TYPE_FILE_SHARE) {
        return "assets/images/provider/fileshare.svg";
      }
    } else if (providerId === constants.PROVIDER_ID_DROPBOX) {
      return "assets/images/provider/dropbox.svg";
    } else if (providerId === constants.PROVIDER_ID_BOX) {
      return "assets/images/provider/dropbox.svg";
    } else if (providerId === constants.PROVIDER_ID_SLACK) {
      return "assets/images/provider/slack.svg";
    } else if (providerId === constants.PROVIDER_ID_OPENTEXT) {
      return "assets/images/provider/dropbox.svg";
    } else if (providerId === constants.PROVIDER_ID_INWEBO) {
      return "assets/images/provider/inwebo.png";
    } else if (providerId === constants.PROVIDER_ID_ZIMBRA) {
      return "assets/images/provider/Zimbra.svg";
    } else if (providerId === constants.PROVIDER_ID_SAP) {
      return "assets/images/provider/SAP2.svg";
    } else if (providerId === constants.PROVIDER_ID_IDECSI) {
      return "assets/logos/logo_black.svg";
    }

  }
}
