import { Component, OnInit, OnDestroy } from '@angular/core';
import { AlertAnswerModel } from 'src/app/i2a/core/models/alert-answer-model';
import { Subscription } from 'rxjs';
import { CultureManagerService, ContextService } from 'src/app/i2a/core/i2a-core.module';
import { AnswerBusinessService } from 'src/app/i2a/core/services/business/answer-business.service';
import { ProviderHelper } from 'src/app/helpers/provider-helper';
import { Constants } from 'src/app/i2a/core/models/Constants';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnDestroy {
  public model: AlertAnswerModel;
  private subscriptions = new Subscription();

  public get ProviderImage():string {
    return ProviderHelper.GetProviderImage(this.model.providerId, this.model.providerTypeId );
  }

  constructor(
    private context: ContextService,
    private cultureManager: CultureManagerService,
    private answerBusiness: AnswerBusinessService,
    private Constants: Constants,
    private route: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit() {
    this.subscriptions.add(this.answerBusiness.Alert$.subscribe(alert => {
      if (!!alert) {
        this.cultureManager.Use(alert.culture).subscribe(value => {
          this.model = alert;
              
          if (this.model.canDismiss)
          {
            let url = this.router.url;
            if (url.endsWith("alert"))
            {
              this.router.navigate(['./summary'], { relativeTo: this.route });
            }
          }
          else
          {
            this.router.navigate(['./dismissed'], { relativeTo: this.route });
          }
         });
      }
    }));
    this.answerBusiness.LoadAlert();
  }


  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
