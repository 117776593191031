import { Injectable } from '@angular/core';
import { DatasourceService } from '../datasource.service';
import { AlertAnswerModel } from '../../models/alert-answer-model';
import { Observable } from 'rxjs';
import { AlertAnswerDismiss } from '../../models/alert-answer-dismiss';

@Injectable({
  providedIn: 'root'
})
export class AlertAnswerDatasourceService {

  private route: string = "alert/answer";

  constructor(private datasource: DatasourceService) {
    console.log("Init AnswerDatasourceService");

  }

  public GetAlert(): Observable<AlertAnswerModel> {
    let partialUrl = `${this.route}`;
    
    return this.datasource.Get<AlertAnswerModel>(partialUrl, null);
  }

  public DismissAlert(model: AlertAnswerDismiss): Observable<any> {
    let partialUrl = `${this.route}/dismiss`;
    
    return this.datasource.Post(partialUrl, model, true);
  }

}
