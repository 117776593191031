<div class="container-fluid p-0 id-page-container">
  <div class="row no-gutters">
    <div class="col">
      <div class="id-content">
        <i2a-page-content [hasPadding]="true" [headerStyle]="headerStyle">
          <ng-container navbar>
            <i2a-nav-bar></i2a-nav-bar>
          </ng-container>
          <ng-container content-body>
            <div class="row id-content-container  justify-content-center">
              <div class="col-12 col-md-10 col-lg-8 col-xl-6 id-content">
                <div class="id-content-title">
                  <!-- <h2 class="id-header-title">{{title}}</h2> -->
                  <ng-content select="[content-title]"></ng-content>
                </div>
                <div>
                  <ng-content select="[content-closed]"></ng-content>
                </div>
                <div class="id-content-subtitle">
                  <ng-content select="[content-subtitle]"></ng-content>
                </div>
                <p class="id-content-description"></p>
                <div class="id-content-body container-fluid">
                  <ng-content select="[content-body]"></ng-content>
                </div>
              </div>
            </div>
          </ng-container>
        </i2a-page-content>
      </div>
    </div>
  </div>
</div>