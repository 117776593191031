<ng-container *ngIf="model">
  
  <app-spinner *ngIf="submitting"></app-spinner>

  <div *ngIf="!submitting" class="panel panel-primary" #scrollMe>

    <div class="card-header" [class.danger]="isSuspicious" [class.warning]="isUnknown" [class.success]="isValid">
      <div class="row">
        <div class="col-10 id-status-title">
          <ng-content select="[headerTitle]"></ng-content>
        </div>
        <div class="col-2 id-status-icon">
          <ng-content select="[headerIcon]"></ng-content>
        </div>
      </div>
    </div>

    <div class="panel-body pt-2 mt-sm-3">

      <form [formGroup]="myForm" (ngSubmit)="submitForm()">
        
        <div class="container">
          <div class="form-group row">
            <label for="comment" class="col-sm-4 col-form-label">{{ "alert.form.comments" | translate }}</label>
            <div class="col-sm-8">
              <textarea #commentInput class="form-control" formControlName="comment">
              </textarea>
            </div>
          </div>
        </div>

        <div class="container">
          <div *ngIf="isValid" class="form-group row">
            <div class="col-sm-12">
              <div class="row">
                <label for="withValidUntilDate" class="col-sm-4 col-form-label id-temporary-access">
                  {{ "alert.form.temporaryAccess" | translate }}
                </label>
                <div class="col-sm-8">
                  <i2a-input-checkbox [(model)]="withValidUntilDate.value" [classNameChecked]="'btn-outline-danger'"
                    [className]="'btn-outline-dark'" [valueChecked]="true" [valueUnChecked]="false">
                  </i2a-input-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div *ngIf="isValid && withValidUntilDate.value" class="container">
          <div class="form-group row">
            <div class="col-sm-12">
              <div class="row">
                <label for="validUntilDate" class="col-sm-4 col-form-label">{{ "alert.form.validUntilDate" | translate }}</label>
                <div class="col-sm-3 input-group">
                  <input ngbDatepicker name="dp" formControlName="validUntilDate" class="form-control"  [placeholder]="defaultFormatDate"
                    #d="ngbDatepicker" [minDate]="minValidityDate" [maxDate]="maxValidityDate" (navigate)="date = $event.next" />
                  <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                    <fa-icon [icon]="['fal', 'calendar-alt']"></fa-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="container">
          <div class="row p-1" [ngStyle]="{'background-color': getBackgroundColor()}">
            <div class="col-12">
               <div class="row">
                    <select name="choose-reason" id="choose-reason" [formControl]="selectedFeedbackReasonsControl" (change)="onChangeFeedbackReason()" class="p-2" >
                        <option value="" disabled selected>{{ 'feedback.reasons.default' | translate }}</option>
                        <option *ngFor="let feedback of feedbacks" [ngValue]="feedback">{{ feedback.viewValue | translate }}</option>
                    </select>
                </div>
  
                <div class="row pt-1" *ngIf="isSendingUsFeeback">
                  <textarea name="feedback-comment" id="feedback-comment" [formControl]="feedbackCommentControl" [placeholder]="'feedback.comment.placeholder' | translate" cols="30" rows="5" class="col-12"></textarea> 	
                </div>
            </div>
      
            <div class="col-12 mt-3" *ngIf="isSendingUsFeeback">
                <div class="row">
                    <i2a-input-checkbox [(model)]="canBeContacted" (modelChange)="onChangeFeedbackCanBeContacted" [classNameChecked]="'btn-outline-primary'" [className]="'btn-outline-dark'" [valueChecked]="true" [valueUnChecked]="false" >
                    </i2a-input-checkbox>
                    <label for="canBeContacted" class="ml-1 my-auto">{{ 'feedback.canBeContacted' | translate }}</label>                
                </div>
            </div> 
          </div>
        </div> -->

        <div class="id-buttons">
          <button class="btn btn-outline-secondary" type="button" (click)="onSummary()">
            {{ "alert.buttons.back" | translate }}
          </button>
          <button class="btn" [disabled]="!isTemporaryAccessValid()" [class.btn-danger]="isSuspicious"
            [class.btn-warning]="isUnknown" [class.btn-success]="isValid" type="button" appPreventDblClick
            [disabled]="!myForm.valid" (click)="submitForm();">
            <fa-icon [icon]="['fal', 'paper-plane']"></fa-icon>
          </button>
        </div>

      </form>

    </div>

  </div>
</ng-container>