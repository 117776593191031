import { Directive, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[appPreventDblClick]'
})
export class PreventDblClickDirective {
  @Input() timeout = null;

  constructor() { }


  @HostListener('click', ['$event'])
  clickEvent(event) {
    const button = (event.srcElement.disabled === undefined) ? event.srcElement.parentElement : event.srcElement;
    button.setAttribute('disabled', true);
    if (!!this.timeout) {
      setTimeout(function () {
        event.srcElement.removeAttribute('disabled');
      }, this.timeout);
    }
  }

}
