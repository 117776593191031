import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TenantCodeResolver } from './resolvers/tenant-code.resolver';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { TokenResolver } from './i2a/core/resolvers/token.resolver';
import { IsValidComponent } from './components/alert/form/is-valid/is-valid.component';
import { IsSuspiciousComponent } from './components/alert/form/is-suspicious/is-suspicious.component';
import { IsUnknownComponent } from './components/alert/form/is-unknown/is-unknown.component';
import { AlertComponent } from './components/alert/alert/alert.component';
import { ProbesStatusComponent } from './components/probes-status/probes-status.component';
import { AlertActionButtonsComponent } from './components/alert/alert-action-buttons/alert-action-buttons.component';
import { AlertDismissedComponent } from './components/alert/alert-dismissed/alert-dismissed.component';

const routes: Routes = [
  { path: 'probes/status', component: ProbesStatusComponent },
  { path: '', redirectTo: '/not-found', pathMatch: 'full' },
  { path: 'not-found', component: NotFoundComponent },
  {
    path: 'tenants/:tenantCode',
    resolve: [TenantCodeResolver],
    children: [
      { path: '', redirectTo: "/not-found", pathMatch: "full" },
      {
        path: ':token', resolve: [TokenResolver], children: [
          { path: '', redirectTo: "alert", pathMatch: "full" },
          {
            path: 'alert', component: AlertComponent, children: [
              { path: 'summary', component: AlertActionButtonsComponent },
              { path: 'dismissed', component: AlertDismissedComponent },
              { path: 'is-valid', component: IsValidComponent },
              { path: 'is-suspicious', component: IsSuspiciousComponent },
              { path: 'is-unknown', component: IsUnknownComponent },
            ]
          },
          { path: '**', redirectTo: 'not-found', pathMatch: 'full' },
          { path: 'not-found', component: NotFoundComponent }
        ]
      },
    ]
  },
  { path: '**', redirectTo: 'not-found', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
