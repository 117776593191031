import { Injectable, Inject, OnDestroy } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { ContextService } from './context.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, first } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ConfigService } from '../configuration/config.service';
import { UrlBuilderHelperService } from 'src/app/helpers/url-builder-helper.service';


@Injectable({
  providedIn: 'root'
})
export class DatasourceService implements OnDestroy {
  private subscriptions = new Subscription();
  private apiEndPoint: string;
  private urlPrefix: string;

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private context: ContextService,
    private configService: ContextService,
    private urlBuilderHelper: UrlBuilderHelperService) {
    console.log("Init datasource");
    this.subscriptions.add(config.Config$.subscribe(data => {

      if (data != null) {
        this.apiEndPoint = data.apiEndPoint;
        this.urlPrefix = data.urlPrefix;
        console.log("datasource => config loaded ");
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  public Get<T>(partialUrl: string, params: { [param: string]: string; }): Observable<T> {
    let url: string = this.urlBuilderHelper.BuildUrl(partialUrl, this.config, this.context.TenantCode);
    let headers = this.getHeaders();
    return this.http.get<T>(url, {
      params: params,
      headers: headers
    }).pipe(
      catchError(this.handleError<T>())
    );
  }

  public Put(partialUrl: string, params: { [param: string]: string; }): Observable<any> {
    let url: string = this.urlBuilderHelper.BuildUrl(partialUrl, this.config, this.context.TenantCode);
    return this.http.put(url, {
      params: params,
      headers: this.getHeaders()
    }).pipe(
      catchError(this.handleError<any>())
    );
  }

  public Post(partialUrl: string, model: any, throwError: boolean = false): Observable<any> {
    let url: string = this.urlBuilderHelper.BuildUrl(partialUrl, this.config, this.context.TenantCode);
    var postReturn = this.http.post(url, model, { headers: this.getHeaders() });
    if (throwError) {
      return postReturn;
    }
    else {
      return postReturn.pipe(
        catchError(this.handleError<any>())
      );
    }
  }

  getHeaders(): HttpHeaders {
    let headers = new HttpHeaders();

    return headers;
  }

  public BuildUrl(partialURL: string): string {
    return this.GetWSTenantizedBaseUrl() + "/" + partialURL;
  }

  private GetWSTenantizedBaseUrl(): string {
    let result = "";
    result = this.apiEndPoint;
    if (!!this.urlPrefix) {
      result = result + "/" + this.urlPrefix;
    }

    if (!!this.context.TenantCode) {
      result = result + "/tenants/" + this.context.TenantCode;
    }
    return result;
  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      return of(result as T);
    };

  }

}
