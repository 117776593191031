import { Component, OnInit } from '@angular/core';
import { AlertAnswerModel } from 'src/app/i2a/core/models/alert-answer-model';
import { ActivatedRoute, Router } from '@angular/router';
import { AnswerBusinessService } from 'src/app/i2a/core/services/business/answer-business.service';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/i2a/core/models/Constants';

@Component({
  selector: 'app-alert-action-buttons',
  templateUrl: './alert-action-buttons.component.html',
  styleUrls: ['./alert-action-buttons.component.scss']
})
export class AlertActionButtonsComponent implements OnInit {

  public model: AlertAnswerModel;
  private subscriptions = new Subscription();
  public isCollapsed: boolean = true;

  constructor(
    private answerBusiness: AnswerBusinessService,
    private route: ActivatedRoute,
    private router: Router,
    private Constants: Constants) {
  }

  ngOnInit() {
    this.subscriptions.add(this.answerBusiness.Alert$.subscribe(alert => {
      this.model = alert;
    }));
  }


  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  isValid() {
    this.router.navigate(['../is-valid'], { relativeTo: this.route });
  }

  isSuspicious() {
    this.router.navigate(['../is-suspicious'], { relativeTo: this.route });
  }

  isUnknown() {
    this.router.navigate(['../is-unknown'], { relativeTo: this.route });
  }

  details() {
    this.router.navigate(['../details'], { relativeTo: this.route });
  }
}