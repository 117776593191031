<div class="id-page">
  <!-- body -->
  <i2a-header [headerStyle]="headerStyle">
    <ng-container navbar>
      <ng-content select="[navbar]"></ng-content>
    </ng-container>
  </i2a-header>
  <div [ngClass]="{'container-fluid': hasPadding, 'id-body': true}">
    <ng-content select="[content-body]"></ng-content>
  </div>
</div>