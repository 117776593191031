<ng-container *ngIf="model">
  <ng-container *ngIf="model.canDismiss">
    <div class="id-buttons">
      <p *ngIf="Constants.SUSPICIOUS_ACCESS == model.type"> {{ 'alert.buttons.title.suspiciousAccess' | translate }}</p>
      <p *ngIf="Constants.SUSPICIOUS_CONFIGURATION_CHANGE == model.type"> {{ 'alert.buttons.title.suspiciousConfigurationChange' | translate }}</p>
      <button class="btn btn-success" (click)="isValid()">
        <fa-icon [icon]="['fal', 'check']" size="2x"></fa-icon>
        <span class="btn-text align-icon-middle">{{ 'alert.buttons.yes' | translate }}</span>
      </button>
      <!-- <button class="btn btn-warning" (click)="isUnknown()">
        <img src="assets/images/unknown.png" height="30" width="30" />
        <span class="btn-text">{{ 'alert.buttons.unknown' | translate }}</span>
      </button> -->
      <button class="btn btn-danger" (click)="isSuspicious()">
        <img src="assets/images/suspicious.png" height="30" width="30" />
        <span class="btn-text"> {{ 'alert.buttons.no' | translate }}</span>
      </button>
    </div>
  </ng-container>
</ng-container>