<ng-container *ngIf="model">
  <div *ngIf="model.canDismiss" class="panel panel-primary">
    <div class="panel-body">
      <app-dismiss-form [status]="status">
        <ng-container headerTitle>
          {{"alert.form.title." + (Constants.SUSPICIOUS_CONFIGURATION_CHANGE == model.type ? "suspiciousConfigurationChange" : "suspiciousAccess") | translate : { response: "alert.form.title.valid" } }}
        </ng-container>
        <ng-container headerIcon>
          <fa-icon [icon]="['fal', 'check']" size="lg"></fa-icon>
        </ng-container>
      </app-dismiss-form>
    </div>
  </div>
</ng-container>