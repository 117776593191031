import { Component, OnInit, OnDestroy } from '@angular/core';
import { AnswerBusinessService } from 'src/app/i2a/core/services/business/answer-business.service';
import { AlertAnswerModel } from 'src/app/i2a/core/models/alert-answer-model';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-alert-dismissed',
  templateUrl: './alert-dismissed.component.html',
  styleUrls: ['./alert-dismissed.component.scss']
})
export class AlertDismissedComponent implements OnInit, OnDestroy {
  public model: AlertAnswerModel;
  private subscriptions = new Subscription();

  constructor(
    private answerBusiness: AnswerBusinessService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.subscriptions.add(this.answerBusiness.Alert$.subscribe(alert => {
      this.model = alert;
      if (this.model.canDismiss)
      {
          this.router.navigate(['../summary'], { relativeTo: this.route });
      }

    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


}
