<ng-container *ngIf="model">
  <div *ngIf="model.canDismiss" class="panel panel-primary">
    <div class="panel-body">
      <app-dismiss-form [status]="status">
        <ng-container headerTitle>
          {{"alert.form.title." + (Constants.SUSPICIOUS_CONFIGURATION_CHANGE == model.type ? "suspiciousConfigurationChange" : "suspiciousAccess") | translate : { response: "alert.form.title.suspicious" } }}
        </ng-container>
        <ng-container headerIcon>
          <img src="assets/images/suspicious_black.png" height="20" width="20" />
        </ng-container>
      </app-dismiss-form>
    </div>
  </div>
</ng-container>