import { Component, OnInit, Input, Inject } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';

@Component({
  selector: 'app-page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss']
})
export class PageContainerComponent implements OnInit {
  @Input() public title: string;
  @Input() public withPadding: boolean;
  public headerStyle: any;

  constructor(
    private sanitizer: DomSanitizer,
    @Inject(I18NEXT_SERVICE) private translateI18Next: ITranslationService
  ) { 
  }

  ngOnInit() {
    if (this.translateI18Next.exists('style.header', {})) {
      this.headerStyle = this.sanitizer.bypassSecurityTrustStyle(this.translateI18Next.t('style.header'));
    }
    else { 
      this.headerStyle = null;
    }
  }


}
