<ng-container *ngIf="model">

  <div *ngIf="model.end" class="container-fluid id-process-content">
    <div class="row">
      <div class="id-process-type col-1">
        <div class="id-picto closed" style="padding: 6px 10px">
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 18.063 18">
            <path fill="#111111" d="M267.113,107.021a5.285,5.285,0,0,0-4.567,7.984l-8.073,8a0.624,0.624,0,0,0-.005.88,0.62,0.62,0,0,0,.878.006l1.119-1.11,2.032,2.063a0.618,0.618,0,0,0,.877.005,0.624,0.624,0,0,0,.006-0.88l-2.032-2.063,0.679-.672,1.414,1.436a0.622,0.622,0,1,0,.883-0.875l-1.415-1.436,0.679-.672,2.031,2.063a0.622,0.622,0,0,0,.883-0.875l-2.031-2.063L263.3,116A5.283,5.283,0,1,0,267.113,107.021Zm-0.057,9.34a4.047,4.047,0,1,1,4.059-4.022A4.045,4.045,0,0,1,267.056,116.361Z"
              transform="translate(-254.281 -107.031)"></path>
          </svg>
        </div>
      </div>
      <div class="id-process-info col-10 col-sm-11">
        <p class="id-title"> {{ "alert.dismissed" | translate }}</p>
        <div class="wrapped-container">
          <div class="id-infos wrapped">{{ model.end | amDateFormat: 'LLLL'}}&nbsp;</div>
        </div>
      </div>
    </div>
  </div>


</ng-container>