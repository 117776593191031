import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'i2a-user-photo',
  templateUrl: './user-photo.component.html',
  styleUrls: ['./user-photo.component.scss']
})
export class UserPhotoComponent implements OnInit {
  @Input() public size: number;
  public userPhotoUrl:string;
  
  constructor(
  ) { }

  ngOnInit() {
  }

}
