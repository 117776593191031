import { Component, OnInit, OnDestroy, Input, Inject, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { SafeHtml, DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ITranslationService, I18NEXT_SERVICE } from 'angular-i18next';
import { AnswerBusinessService } from 'src/app/i2a/core/services/business/answer-business.service';
import { AlertAnswerModel } from 'src/app/i2a/core/models/alert-answer-model';
import { ContextService } from 'src/app/i2a/core/i2a-core.module';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'i2a-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit, OnDestroy {
  public model: AlertAnswerModel;
  @Input() public displayMenu: boolean;
  public logo:SafeUrl;
  public logoStyle:any;
  public logoContainerStyle:any;
  
  private subscriptions = new Subscription();
  public tenantCode: string;
  public isCollapsed: boolean;
  public isMultiTenants: boolean;
  public expertUrl: string;
  public monitoringUrl: string;
  public prepositionArgs: any;
  public isIE: boolean;

  @ViewChild('navbarTitle', { static: true }) navbarTitle: ElementRef;
  @ViewChild('navbarSubTitle', { static: true }) navbarSubTitle: ElementRef;

  constructor(
    public context: ContextService,
    private answerBusiness: AnswerBusinessService,
    private sanitizer: DomSanitizer,
    @Inject(I18NEXT_SERVICE) private translateI18Next: ITranslationService,
    private deviceService: DeviceDetectorService) {
    this.isCollapsed = true;
    this.isMultiTenants = false;
    this.displayMenu = true;
    //https://www.geeksforgeeks.org/how-to-detect-the-user-browser-safari-chrome-ie-firefox-and-opera-using-javascript/
    this.isIE = this.deviceService.userAgent.indexOf("MSIE") > -1 || this.deviceService.userAgent.indexOf("rv:") > -1; 
  }

  ngOnInit(): void {
    this.subscriptions.add(this.answerBusiness.Alert$.subscribe(alert => {
      this.model = alert;
    }));
    this.logo = this.sanitizeLogo(this.translateI18Next.t('images.logo.png'));
    let logostr = this.translateI18Next.t('style.logo');
    this.logoStyle = this.sanitizer.bypassSecurityTrustStyle(logostr);
    this.logoContainerStyle = this.sanitizer.bypassSecurityTrustStyle(this.translateI18Next.t('style.logoContainer'));
  }

  sanitizeLogo(base64: string): SafeUrl
  {
    return this.sanitizer.bypassSecurityTrustUrl(`data:image/png;base64,${base64}`);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  //#region IE
  onLeave(): void {
    if (this.isIE) {
      const color = this.translateI18Next.t('style.ie.color');
      this.navbarTitle.nativeElement.style.color = color;
      this.navbarSubTitle.nativeElement.style.color = color;
    }
  }

  onEnter(): void {
    if (this.isIE) {
      const colorHover = this.translateI18Next.t('style.ie.colorHover');
      this.navbarTitle.nativeElement.style.color = colorHover;
      this.navbarSubTitle.nativeElement.style.color = colorHover;
    }
  }
  //#endregion

}
