import { Injectable } from "@angular/core";
import { UrlBuilderHelperService } from 'src/app/helpers/url-builder-helper.service';
import { LocalizationRequester } from '../../models/localization-requester';
import { ConfigService } from '../../configuration/config.service';

@Injectable()
export class LocalizationUrlService {

    constructor(private urlBuilderHelper: UrlBuilderHelperService) {

    }

    public getResourcesUrl(requester: LocalizationRequester, configService: ConfigService, tenantCode: string): string {
        let partialUrl = `localizations/resources?culture={{lng}}&requester=${requester}`;

        let url: string = this.urlBuilderHelper.BuildUrl(partialUrl, configService, tenantCode);
        return url;
    }


}