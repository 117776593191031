import { Inject, Injectable, Pipe, PipeTransform, EventEmitter, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ITranslationService, I18NEXT_SERVICE, I18NEXT_NAMESPACE, I18NEXT_SCOPE, I18NextPipe } from 'angular-i18next';
import { Subscription } from 'rxjs';

@Injectable()
@Pipe({
  name: 'translate',
  pure: false
})
export class translatePipe extends I18NextPipe implements PipeTransform, OnDestroy {
  private subscriptions = new Subscription();

  constructor(
    @Inject(I18NEXT_SERVICE) translateI18Next: ITranslationService,
    @Inject(I18NEXT_NAMESPACE) ns: string | string[],
    @Inject(I18NEXT_SCOPE) scope: string | string[],
    private _ref: ChangeDetectorRef) {
    super(translateI18Next, ns, scope)


    this.subscriptions.add(translateI18Next.events.languageChanged.subscribe((lang: string) => {
      this._ref.markForCheck();
    }));

    this.subscriptions.add(translateI18Next.events.loaded.subscribe(() => {
      this._ref.markForCheck();
    }));

  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}