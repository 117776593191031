import { Component, OnInit, OnDestroy } from '@angular/core';
import { AlertAnswerModel } from 'src/app/i2a/core/models/alert-answer-model';
import { Subscription } from 'rxjs';
import { AnswerBusinessService } from 'src/app/i2a/core/services/business/answer-business.service';

@Component({
  selector: 'app-alert-processed',
  templateUrl: './alert-processed.component.html',
  styleUrls: ['./alert-processed.component.scss']
})
export class AlertProcessedComponent implements OnInit, OnDestroy {
  public model: AlertAnswerModel;
  private subscriptions = new Subscription();

  constructor(
    private answerBusiness: AnswerBusinessService) { }

  ngOnInit() {
    this.subscriptions.add(this.answerBusiness.Alert$.subscribe(alert => {
      this.model = alert;
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


}
