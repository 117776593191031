import { Injectable } from "@angular/core";
import { ConfigService } from '../i2a/core/configuration/config.service';

@Injectable()
export class UrlBuilderHelperService {
    constructor() {
    }

    public BuildUrl(partialURL: string, configService: ConfigService, tenantCode: string): string {
        return this.GetWSTenantizedBaseUrl(configService.Config.apiEndPoint, configService.Config.urlPrefix, tenantCode) + "/" + partialURL;
    }

    private GetWSTenantizedBaseUrl(apiEndPoint: string, urlPrefix: string, tenantCode: string): string {
        let result = "";
        result = apiEndPoint;
        if (!!urlPrefix) {
            result = result + "/" + urlPrefix;
        }

        if (!!tenantCode) {
            result = result + "/tenants/" + tenantCode;
        }
        return result;
    }

}