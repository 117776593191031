import { Component, OnInit } from '@angular/core';
import { AnswerBusinessService } from 'src/app/i2a/core/services/business/answer-business.service';
import { AlertAnswerModel } from 'src/app/i2a/core/models/alert-answer-model';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/i2a/core/models/Constants';

@Component({
  selector: 'app-alert-details',
  templateUrl: './alert-details.component.html',
  styleUrls: ['./alert-details.component.scss']
})
export class AlertDetailsComponent implements OnInit {
  public isCollapsed: boolean = true;
  public model: AlertAnswerModel;
  private subscriptions = new Subscription();

  constructor(
    private Constants: Constants,
    private answerBusiness: AnswerBusinessService) { }

  ngOnInit() {
    this.subscriptions.add(this.answerBusiness.Alert$.subscribe(alert => {
      this.model = alert;
    }));
  }


  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}