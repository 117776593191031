import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ConfigService } from '../configuration/config.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContextService implements OnDestroy {
  private subscriptions = new Subscription();
  public DefaultCulture: string;
  public ClientId: string;

  //#region TenantCode
  public tenantCodeSubject?: BehaviorSubject<string>;
  public TenantCode$: Observable<string>

  get TenantCode(): string {
    return this.tenantCodeSubject.value;
  }

  set TenantCode(tenantCode: string) {
    this.tenantCodeSubject.next(tenantCode);
  }

  //#endregion

  //#region Token
  private tokenSubject?: BehaviorSubject<string>;
  public Token$: Observable<string>;

  get Token(): string {
    return this.tokenSubject.value;
  }

  set Token(token: string) {
    this.tokenSubject.next(token);
  }

  //#endregion

  //#region Culture
  private cultureSubject?: BehaviorSubject<string>;
  public Culture$: Observable<string>;

  get Culture(): string {
    return this.cultureSubject.value;
  }

  set Culture(culture: string) {
    this.cultureSubject.next(culture);
  }
  //#endregion

  constructor(config: ConfigService) {
    console.log("Init context");
    this.tenantCodeSubject = new BehaviorSubject<string>("");
    this.TenantCode$ = this.tenantCodeSubject.asObservable();
    this.tokenSubject = new BehaviorSubject<string>(null);
    this.Token$ = this.tokenSubject.asObservable();
    this.cultureSubject = new BehaviorSubject<string>(environment.defaultCulture);
    this.Culture$ = this.cultureSubject.asObservable();
    this.subscriptions.add(config.Config$.subscribe(data => {
      if (data != null) {
        this.DefaultCulture = data.defaultCulture;
        this.ClientId = data.clientId;
        this.cultureSubject.next(data.defaultCulture);
        console.log("context => config loaded ");
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


}
