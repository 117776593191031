import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCalendarAlt, faPaperPlane, faCheck, faQuestion, faHandRock, faInfo, faAngleDown, faAngleUp, faTimes, } from '@fortawesome/pro-light-svg-icons';

import { I2aCoreModule } from './i2a/core/i2a-core.module';

import { MomentModule } from 'ngx-moment';

import { NotFoundComponent } from './components/not-found/not-found.component';
import { IsValidComponent } from './components/alert/form/is-valid/is-valid.component';
import { IsSuspiciousComponent } from './components/alert/form/is-suspicious/is-suspicious.component';
import { IsUnknownComponent } from './components/alert/form/is-unknown/is-unknown.component';
import { AlertComponent } from './components/alert/alert/alert.component';
import { I18NextModule } from 'angular-i18next';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { SpinnerComponent } from './components/shared/spinner/spinner.component';
import { ProbesStatusComponent } from './components/probes-status/probes-status.component';
import { PreventDblClickDirective } from './directives/prevent-dbl-click.directive';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { CriticalityToIconComponent } from './components/shared/criticality-to-icon/criticality-to-icon.component';

import { DismissFormComponent } from './components/alert/form/dismiss-form/dismiss-form.component';
import { PageContainerComponent } from './components/shared/page-container/page-container.component';
import { AlertActionButtonsComponent } from './components/alert/alert-action-buttons/alert-action-buttons.component';
import { AlertDismissedComponent } from './components/alert/alert-dismissed/alert-dismissed.component';
import { AlertDetailsComponent } from './components/alert/alert-details/alert-details.component';
import { AlertProcessedComponent } from './components/alert/alert-processed/alert-processed.component';
import { environment } from 'src/environments/environment';
import { InputCheckboxComponent } from './components/shared/form/input/input-checkbox/input-checkbox.component';
import { TenantCodeResolver } from './resolvers/tenant-code.resolver';
import { NavBarBaseComponent } from './components/shared/page/nav-bar-base/nav-bar-base.component';
import { NavBarComponent } from './components/shared/nav-bar/nav-bar.component';
import { PageContentComponent } from './components/shared/page/page-content/page-content.component';
import { HeaderComponent } from './components/shared/page/header/header.component';
import { UserPhotoComponent } from './components/shared/user-photo/user-photo.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    AlertComponent,
    AlertActionButtonsComponent,
    IsValidComponent,
    IsSuspiciousComponent,
    IsUnknownComponent,
    SpinnerComponent,
    ProbesStatusComponent,
    PreventDblClickDirective,
    CriticalityToIconComponent,
    DismissFormComponent,
    PageContainerComponent,
    AlertDismissedComponent,
    AlertDetailsComponent,
    AlertProcessedComponent,
    InputCheckboxComponent,
    NavBarBaseComponent,
    NavBarComponent,
    PageContentComponent,
    HeaderComponent,
    UserPhotoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    FontAwesomeModule,
    FormsModule,
    MomentModule,
    ReactiveFormsModule,
    I2aCoreModule.forRoot(environment),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    LoadingBarHttpClientModule
  ],
  providers: [
    TenantCodeResolver,
    {
      provide: DeviceDetectorService
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: []
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    //library.addIconPacks(fas);
+   library.addIcons(faCalendarAlt, faPaperPlane, faCheck, faQuestion, faHandRock, faAngleDown, faAngleUp, faInfo, faTimes);
  }
}
