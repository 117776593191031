import { Injectable } from '@angular/core';
import { AlertAnswerDatasourceService } from '../../i2a-core.module';
import { AlertAnswerModel } from '../../models/alert-answer-model';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AlertAnswerDismiss } from '../../models/alert-answer-dismiss';
import { AlertAnswer } from '../../models/alert-answer';
import { Feedback } from '../../models/feedback';

@Injectable({
  providedIn: 'root'
})
export class AnswerBusinessService {
  private subscriptions = new Subscription();

  private alertSubject?: BehaviorSubject<AlertAnswerModel>;
  public Alert$: Observable<AlertAnswerModel>;

  constructor(
    private answerDatasource: AlertAnswerDatasourceService,
    private router: Router) {
    console.log("Init AnswerBusinessService");
    this.alertSubject = new BehaviorSubject<AlertAnswerModel>(null);
    this.Alert$ = this.alertSubject.asObservable();
  }

  get Alert(): AlertAnswerModel {
    return this.alertSubject.value;
  }

  public LoadAlert() {
    this.subscriptions.add(this.answerDatasource.GetAlert().subscribe(alert => {
      this.alertSubject.next(alert);
    }));
  }

  public IsValid(comment: string, valideUntilDate: Date, feedback: Feedback): Observable<any> {
    var model: AlertAnswerDismiss = {
      comment: comment,
      answer: AlertAnswer.Valid,
      validUntilDate: valideUntilDate != null ? valideUntilDate.toISOString() : null,
      feedback: feedback
    };
    return this.answerDatasource.DismissAlert(model);
  }

  public IsSuspicious(comment: string, feedback: Feedback): Observable<any> {
    var model: AlertAnswerDismiss = {
      comment: comment,
      answer: AlertAnswer.Suspicious,
      validUntilDate: null,
      feedback: feedback
    };

    return this.answerDatasource.DismissAlert(model);
  }

  public IsUnknown(comment: string, feedback: Feedback): Observable<any> {
    var model: AlertAnswerDismiss = {
      comment: comment,
      answer: AlertAnswer.Unknown,
      validUntilDate: null,
      feedback: feedback
    };

    return this.answerDatasource.DismissAlert(model);
  }
}
