import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Injectable } from "@angular/core"
import { Observable } from "rxjs";
import { ContextService } from '../services/context.service';
import { ConfigService } from '../configuration/config.service';

@Injectable()
export class AuthenticationHttpInterceptor implements HttpInterceptor {
    constructor(
        private context: ContextService,
        private config: ConfigService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let request = req.clone({
            setHeaders: {
                "X-I2A-ActionToken": this.context.Token,
                "X-I2A-ClientId": this.context.ClientId
            }
        });

        return next.handle(request);
    }

}