import { Component, OnInit, Input } from '@angular/core';
import { Criticality } from 'src/app/i2a/core/models/Criticality';

@Component({
  selector: 'app-criticality-to-icon',
  templateUrl: './criticality-to-icon.component.html',
  styleUrls: ['./criticality-to-icon.component.scss']
})
export class CriticalityToIconComponent implements OnInit {

  public CriticalityEnum = Criticality;
  @Input() public criticality: Criticality;

  constructor() { }

  ngOnInit() {
  }

}
