import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from 'rxjs';
import { ContextService } from '../services/context.service';

@Injectable()
export class TokenResolver implements Resolve<Observable<any>> {
  constructor(private context: ContextService) { }

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<Observable<any> | Promise<any> | any> {
    console.log(`set Token ${route.params["token"]}`);
    this.context.Token = route.params["token"];
    return true;
  }
}