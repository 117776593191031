import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnswerBusinessService } from 'src/app/i2a/core/services/business/answer-business.service';
import { DismissStatus } from 'src/app/models/dismiss-status';
import { AlertFormBase } from '../alert-form-base';
import { Constants } from 'src/app/i2a/core/models/Constants';

@Component({
  selector: 'app-is-unknown',
  templateUrl: './is-unknown.component.html',
  styleUrls: ['./is-unknown.component.scss']
})
export class IsUnknownComponent extends AlertFormBase implements OnInit, OnDestroy {
  private status = DismissStatus.IsUnknown;

  constructor(
    private Constants: Constants,
    answerBusiness: AnswerBusinessService,
    route: ActivatedRoute,
    router: Router) {
    super(answerBusiness, route, router);
  }

}
